import React, { useContext } from "react"
import { filterContext } from "../../context/filterContext/filterContext"
import { filter_by, order_by, sort_by } from "../../context/filterContext/filterConstants"
import cls from "classnames"

const SalonFilters = () => {
  const ctx = useContext(filterContext)
  return (
    <div className="uk-relative">
      <button
        className="uk-button uk-float-right uk-button-default uk-button-medium uk-box-shadow-small" style={{border: "0px"}}
        uk-toggle="target: #filters-container; animation: uk-animation-scale-up uk-transform-origin-top-right"
      >
        <span className="uk-icon" uk-icon="icon: settings"></span>
        Filter
        <span className="uk-icon" uk-icon="icon: triangle-down"></span>
      </button>
      <div
        id="filters-container"
        className="pa3 uk-box-shadow-small"
        style={{backgroundColor: "#fff" }}
      >
        <div className="flex flex-column flex-row-ns">
          <div className="w-100 w-20-ns pa2  br b--black-10 flex flex-row">
            <div className="">
              <p className="uk-text-emphasis uk-text-bold">Sort By</p>
              <ul className="uk-list uk-link-muted">
                <li className={cls({ "active": ctx.sortBy === sort_by.NAME })}>
                  <a onClick={() => ctx.setSortBy(ctx.sortBy === sort_by.NAME ? sort_by.NONE : sort_by.NAME)}>Name</a>
                </li>
                <li className={cls({ "active": ctx.sortBy === sort_by.PRICE })}>
                  <a
                    onClick={() => ctx.setSortBy(ctx.sortBy === sort_by.PRICE ? sort_by.NONE : sort_by.PRICE)}>Price</a>
                </li>
                <li className={cls({ "active": ctx.sortBy === sort_by.RATING })}>
                  <a
                    onClick={() => ctx.setSortBy(ctx.sortBy === sort_by.RATING ? sort_by.NONE : sort_by.RATING)}>Rating</a>
                </li>
                <li className={cls({ "active": ctx.sortBy === sort_by.DISTANCE })}>
                  <a
                    onClick={() => ctx.setSortBy(ctx.sortBy === sort_by.DISTANCE ? sort_by.NONE : sort_by.DISTANCE)}>Distance</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="wp-100 w-30-ns br b--black-10 pa2 ph3-ns">
            <div className="">
              <p className="uk-text-emphasis uk-text-bold">Sort Order</p>
              <ul className="uk-list uk-link-muted">
                <li
                  className={cls([{ "disabled": (ctx.sortBy === sort_by.NAME || ctx.sortBy === sort_by.NONE) }, { "active": ctx.orderBy === order_by.HIGH_TO_LOW }])}>
                  <a onClick={() => ctx.setOrderBy(order_by.HIGH_TO_LOW)}>High to Low</a>
                </li>
                <li
                  className={cls([{ "disabled": (ctx.sortBy === sort_by.NAME || ctx.sortBy === sort_by.NONE) }, { "active": ctx.orderBy === order_by.LOW_TO_HIGH }])}>
                  <a onClick={() => ctx.setOrderBy(order_by.LOW_TO_HIGH)}>Low to High</a>
                </li>
                <li
                  className={cls([{ "disabled": (ctx.sortBy !== sort_by.NAME)}, { "active": ctx.orderBy === order_by.A_TO_Z }])}>
                  <a onClick={() => ctx.setOrderBy(order_by.A_TO_Z)}>A to Z</a>
                </li>
                <li
                  className={cls([{ "disabled": (ctx.sortBy !== sort_by.NAME)}, { "active": ctx.orderBy === order_by.Z_TO_A }])}>
                  <a onClick={() => ctx.setOrderBy(order_by.Z_TO_A)}>Z to A</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-100 w-30-ns pa2 ph3-ns">
            <div className="">
              <p className="uk-text-emphasis uk-text-bold">Type</p>
              <ul className="uk-list uk-link-muted">
                <li className={cls({"active":ctx.filterBy===filter_by.NONE})}
                    onClick={()=>ctx.setFilterBy(filter_by.NONE)}>
                  <a  >All</a>
                </li>
                <li className={cls({"active":ctx.filterBy===filter_by.UNISEX})}
                    onClick={()=>ctx.setFilterBy(filter_by.UNISEX)}>
                  <a >Unisex</a>
                </li>
                <li
                   className={cls({"active":ctx.filterBy===filter_by.MALE})}
                    onClick={()=>ctx.setFilterBy(filter_by.MALE)}>
                  <a>Only Male</a>
                </li>
                <li  className={cls({"active":ctx.filterBy===filter_by.FEMALE})}
                    onClick={()=>ctx.setFilterBy(filter_by.FEMALE)}>
                  <a>Only Female</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalonFilters
