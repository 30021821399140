import React, { useContext, useEffect, useState } from "react"
import Layout from "../components/layout/layout"
import SalonList from "../components/salon-list/salon-list"
import SalonFilters from "../components/salon-filters/salon-filters"
import SearchBar from "../components/search-bar/search-bar"
import { searchContext } from "../context/searchContext/searchContext"
import { filterContext } from "../context/filterContext/filterContext"
import { order_by, sort_by, filter_by } from "../context/filterContext/filterConstants"
import { window } from "browser-monads"
import SEO from "../components/seo/seo"
import { getDistanceFromLatLonInKm } from "../uitlity/Utility"
import Footer from "../components/footer/footer"



const Listing = () => {
  let searchCtx = useContext(searchContext)
  let filterCtx = useContext(filterContext)

  let storeList = [...searchCtx.searchSalonList];
  const [userLocation ,setUserLocation] = useState(null);
  storeList = storeList.filter(store=>store["is_active"])
  if(filterCtx.filterBy!==filter_by.NONE){
    storeList = storeList.filter(store=>store["salon_type"]===filterCtx.filterBy)
  }
  if (filterCtx.sortBy) {
    switch (filterCtx.sortBy) {
      case sort_by.NAME:
        if (filterCtx.orderBy === order_by.A_TO_Z) storeList.sort((a, b) => (a.name > b.name) ? 1 : -1)
        else storeList.sort((a, b) => (a.name > b.name) ? -1 : 1)
        break
      case sort_by.DISTANCE:
        if(userLocation){
          if (filterCtx.orderBy === order_by.HIGH_TO_LOW) storeList.sort(
            (a, b) => (getDistanceFromLatLonInKm(a.latitude,a.longitude,userLocation.lat,userLocation.long)> getDistanceFromLatLonInKm(b.latitude,b.longitude,userLocation.lat,userLocation.long)) ? -1 : 1)
          else storeList.sort(
            (a, b) => (getDistanceFromLatLonInKm(a.latitude,a.longitude,userLocation.lat,userLocation.long)> getDistanceFromLatLonInKm(b.latitude,b.longitude,userLocation.lat,userLocation.long)) ? 1 : -1)
        }
        break
      case sort_by.RATING:
        if (filterCtx.orderBy === order_by.HIGH_TO_LOW) storeList.sort((a, b) => (a.rating > b.rating) ? -1 : 1)
        else storeList.sort((a, b) => (a.rating > b.rating) ? 1 : -1)
        break
      case sort_by.PRICE:
        if (filterCtx.orderBy === order_by.HIGH_TO_LOW) storeList.sort(
          (a, b) => ((a.services?(a.services.reduce((prev,service)=>prev+service.price,0)/a.services.length):0) > (b.services?(b.services.reduce((prev,service)=>prev+service.price,0)/b.services.length):0)) ? -1 : 1)
        else storeList.sort(
          (a, b) => ((a.services?(a.services.reduce((prev,service)=>prev+service.price,0)/a.services.length):0) > (b.services?(b.services.reduce((prev,service)=>prev+service.price,0)/b.services.length):0)) ? 1 : -1)
        break

    }
  }
  useEffect(() => {
    if(filterCtx.sortBy === sort_by.DISTANCE){
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        setUserLocation({
          lat: coords.latitude,
          long: coords.longitude
        });
      })
    }
  }, [filterCtx.sortBy])

  return (
    <>
      <Layout>
        <SEO title="Listing" />
        <div className="uk-section uk-section-muted">
          <div className="uk-container uk-container-medium">
            <SearchBar redirect={false}/>
            {/* <app-salon-list-page-search></app-salon-list-page-search> */}
          </div>
        </div>
        <div
          className="uk-container uk-container-medium"
          style={{ padding: "0 !important", marginTop: "-25px" }}
        >
          <SalonFilters/>
        </div>
        <div className="uk-section">
          <div className="uk-container uk-container-medium">
            <SalonList list={storeList}/>
          </div>
        </div>
        <Footer />
      </Layout>
    </>
  )
}

export default Listing
