export const showError = (error) => {
  window.UIkit.notification(error, {
    status: "danger"
  })
}
export const showDialog = (error) => {
  window.UIkit.notification(error, {
    status: "danger"
  })
}

export const  showSuccess = (error) => {
  window.UIkit.notification(error, {
    status: "success"
  })
}
export const showConfirmation = (message,onConfirm,onCancel) => {
  window.UIkit.modal.confirm(message).then(onConfirm,onCancel);
}
export const showMobilePrompt = (message) => {
  return window.UIkit.modal.prompt(message, '')
}
export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2-lat1);  // deg2rad below
  let dLon = deg2rad(lon2-lon1);
  let a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;  // Distance in km
}

export function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export function GoogleMapsURLToEmbedURL(GoogleMapsURL)
{
    let coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(GoogleMapsURL);
    if(coords!=null)
    {
        let coordsArray = coords[1].split(',');
        return "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d"+coordsArray[1]+"!3d"+coordsArray[0]+"!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098";
    }
}